<template>
  <div class="livreurs adjust_width">
    <title_cat>Factures</title_cat>

    <div v-if="!factures.length" class="info">

      <div class="t">Vous n'avez pas encore de factures</div>
      <div class="p">
        Vos factures s'afficheront sur cette page chaque fin de mois.
      </div>
    </div>


    <div class="factures" v-for="n in factures" :key="n.id">
      <div class="e" @click="download(n.id, n.title, n.key)">
        {{ n.title }}
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M8,13H10.55V10H13.45V13H16L12,17L8,13M19.35,10.04C21.95,10.22 24,12.36 24,15A5,5 0 0,1 19,20H6A6,6 0 0,1 0,14C0,10.91 2.34,8.36 5.35,8.04C6.6,5.64 9.11,4 12,4C15.64,4 18.67,6.59 19.35,10.04M19,18A3,3 0 0,0 22,15C22,13.45 20.78,12.14 19.22,12.04L17.69,11.93L17.39,10.43C16.88,7.86 14.62,6 12,6C9.94,6 8.08,7.14 7.13,8.97L6.63,9.92L5.56,10.03C3.53,10.24 2,11.95 2,14A4,4 0 0,0 6,18H19Z" /></svg>
      </div>
    </div>
    

  </div>
</template>

<script>

export default {
  name: "factures",
  data() {
    return {
      factures: {}
    }
  },
  created() {
    this.sync()
  },
  methods: {
    sync() {
      this.$http.get('/bo/facture').then((factures) => {
        this.factures = factures.data
      })
    },
    download(id, title, key) {
      this.$http.get('/bo/facture/' + id).then(({data}) => {

        console.log(data.data.data)

        const intArray = new Uint8Array(data.data.data);
        var blob = new Blob([intArray], {type: "application/pdf"});
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title} - ${key}.pdf`;
        link.click();
      })
    }
    
  }
}
</script>

<style scoped>

.info { background: var(--input); padding: 16px 20px; margin-bottom: 26px; margin-top: -16px; }
.info .qrcode { flex: 0 0 auto; width: 300px; height: 300px; margin-top: 6px; }
.info .t { font-size: 15px; padding-bottom: 8px; }
.info .p { font-size: 14px; line-height: 21px; color: var(--black-sub); font-weight: 400; }

.factures .e { display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid var(--gris); font-size: 16px; height: 50px; cursor: pointer; }
.factures .e svg { fill: var(--black-main) }

@media only screen and (min-width: 800px) {

}



</style>